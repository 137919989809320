import React from 'react';
import FacebookIcon from '../../icons/FacebookIcon';
import LinkedInIcon from '../../icons/LinkedInIcon';
import TwitterIcon from '../../icons/TwitterIcon';
import YoutubeIcon from '../../icons/YoutubeIcon';

const FooterCopy = () => ({
  columns: [
    {
      title: 'Apryse SDK',
      items: [
        {
          text: 'The Platform',
          to: '/platform-overview/',
          extraInfo: 'NEW',
        },
        {
          text: 'All Capabilities',
          to: '/products/',
          extraInfo: '',
        },
        {
          text: 'PDF',
          to: 'https://apryse.com/products/core-sdk/pdf/',
          extraInfo: '',
        },
        {
          text: 'MS Office',
          to: '/office-sdk/',
          extraInfo: '',
        },
        {
          text: 'CAD',
          to: '/cad-sdk/',
          extraInfo: '',
        },
        {
          text: 'Salesforce',
          to: '/salesforce/',
          extraInfo: '',
        },
        {
          text: 'Video',
          to: '/video-annotation-collaboration-sdk/',
          extraInfo: '',
        },
      ],
    },
    {
      title: 'Resources',
      items: [
        {
          text: 'Documentation',
          to: '/',
          extraInfo: '',
        },
        {
          text: 'Download Center',
          to: 'https://dev.apryse.com/',
          extraInfo: '',
        },
        {
          text: 'Support',
          to: 'https://apryse.com/support/',
          extraInfo: '',
        },
        {
          text: 'Community',
          to: 'https://community.apryse.com/',
          extraInfo: '',
        },
        {
          text: 'Newsletter',
          to: '/company/stay-in-touch/',
          extraInfo: '',
        },
        {
          text: 'Brand Assets',
          to: '/brand-guidelines/',
          extraInfo: '',
        },
      ],
    },
    {
      title: 'COMPANY',
      items: [
        {
          text: 'About Us',
          to: '/company/',
          extraInfo: '',
        },
        {
          text: 'Customers',
          to: 'https://apryse.com/customers/',
          extraInfo: '',
        },
        {
          text: 'Our People',
          to: '/company/our-people/',
          extraInfo: '',
        },
        {
          text: 'Our Brands',
          to: '/brands/',
          extraInfo: '',
        },
        {
          text: 'Licensing',
          to: 'https://apryse.com/legal/',
          extraInfo: '',
        },
        {
          text: 'Careers',
          to: '/company/careers/',
          extraInfo: 'Hiring!',
        },
        {
          text: 'Contact Us',
          to: 'https://apryse.com/contact-us/',
          extraInfo: '',
        },
      ],
    },
    {
      title: 'Popular content',
      items: [
        {
          text: 'Docx to PDF NodeJS',
          to: '/blog/nodejs/generate-pdf-convert-docx-to-pdf-with-nodejs/',
          extraInfo: '',
        },
        {
          text: 'Vue PDF Viewer',
          to: '/blog/webviewer/how-to-build-a-pdf-viewer-with-vuejs/',
          extraInfo: '',
        },
        {
          text: 'Build an Android OCR Scanner',
          to: '/blog/android/build-a-document-scanner-with-ocr/',
          extraInfo: '',
        },
        {
          text: ' Angular MS Office Viewer',
          to: '/blog/ms-office-sdk/office-viewer-angular/',
          extraInfo: '',
        },
        {
          text: 'Salesforce PDF Viewer & Editor',
          to: '/blog/webviewer/add-pdf-viewer-editor-to-salesforce-as-lwc/',
          extraInfo: '',
        },
        {
          text: 'Word to PDF SDK',
          to: '/blog/ms-office-sdk/convert-word-to-pdf/',
          extraInfo: '',
        },
        {
          text: 'Javascript PDF Rendering',
          to: '/blog/pdf-js/guide-to-pdf-js-rendering/',
          extraInfo: '',
        },
        {
          text: 'Generate PDF Javascript',
          to: '/blog/javascript/how-to-generate-pdfs-with-javascript/',
          extraInfo: '',
        },
        {
          text: 'Browse all blogs',
          to: '/blog/',
          extraInfo: '',
          bold: true,
        },
      ],
    },
  ],
  legal: {
    copyright: '© 2022 Apryse Software Inc. All rights reserved.',
    links: [
      {
        text: 'Privacy',
        to: '/privacy/',
      },
      {
        text: 'Terms of Use',
        to: '/legal/',
      },
    ],
    socials: [
      {
        to: 'https://twitter.com/pdftron',
        icon: <TwitterIcon fill='gray.700' _hover={{ fill: 'gray.0' }} />,
      },
      {
        to: 'https://www.youtube.com/channel/UCCH2ZkSxh2Kx0HnDvAqZtWA',
        icon: <YoutubeIcon fill='gray.700' _hover={{ fill: 'gray.0' }} />,
      },
      {
        to: 'https://www.linkedin.com/company/pdftron-systems-inc/',
        icon: <LinkedInIcon fill='gray.700' _hover={{ fill: 'gray.0' }} />,
      },
      {
        to: 'https://www.facebook.com/pdftron',
        icon: <FacebookIcon fill='gray.700' _hover={{ fill: 'gray.0' }} />,
      },
    ],
  },
});

export default FooterCopy;

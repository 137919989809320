import { Box, Flex } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useContext, useMemo } from 'react';
import { getPathInfo, goToPlatform } from '../../services/URL';
import Dropdown from '../Dropdown';
import _copy from '../../copy/header';
import useCopy from '../../hooks/useCopy';
import { Platforms } from '../../icons/PlatformIcon';
import HeaderState from '../../context/HeaderState';
import ResponsiveButtonTabs from '../ResponsiveButtonTabs';

export type DropdownOption = Partial<{
  header?: string;
  title?: string;
  mobileTitle?: string | any;
  value?: string;
  link?: string;
  isExternal?: boolean;
}>;
interface PathInfo {
  rootPath?: string;
  platform?: Platforms;
  section?: string;
  guidePath?: string;
}
interface PlatformDropdownProps {
  className?: string;
  pathInfo: PathInfo;
  pathname: string;
}

interface DocumentationTypeDropdownProps {
  className?: string;
  pathInfo: PathInfo;
}

const CoreDropDown = {
  title: "Server SDK",
  mobileTitle: <span>Server SDK</span>,
  value: "core",
  link: "/core/guides/get-started/",
};

const getSelectedOption = (options: DropdownOption[], selected: string) =>
  options.find((option) => {
    if (!option.value) {
      return false;
    }
    const { value } = option;
    // Hard overwrite downloads because the path is different than the title
    if(value === 'downloads' && selected === 'download') return true;

    return value.toLowerCase() === selected?.toLowerCase();
  });

const DocumentationButtonGroup = (props: DocumentationTypeDropdownProps) => {
  const { pathInfo } = props;
  const copy = useCopy(_copy);
  const buttons = copy.documentation.buttons?.[pathInfo.platform]?.buttons;
  const buttonOptions = useMemo<DropdownOption[]>(
    () =>
      buttons
        ? buttons.map((button) => ({
            title: button.title,
            mobileTitle: button.mobileTitle,
            link: button.to,
            value: button.title.toLowerCase(),
            isExternal: button.to?.startsWith('http') || button.to?.startsWith('/api'), 
          }))
        : [],
    [buttons]
  );

  const selectedDocumentation = useMemo(
    () => getSelectedOption(buttonOptions, pathInfo.section || 'guides'),
    [buttonOptions, pathInfo]
  );

  if (buttonOptions.length === 0) return null;
  return (
    <ResponsiveButtonTabs
      items={buttonOptions}
      selectedDocumentation={selectedDocumentation}
    />
  );
};

const PlatformDropdown = (props: PlatformDropdownProps) => {
  const { pathInfo } = props;
  const copy = useCopy(_copy);
  const selectedPlatform = getSelectedOption(
    copy.documentation.dropdown,
    pathInfo.platform
  ) || CoreDropDown;
  const { allRelatedDocsField } = useStaticQuery(allRelatedDocsFieldQuery);
  const relatedRoutes = useMemo(
    () =>
      allRelatedDocsField.edges.reduce((acc, edge) => {
        return {
          ...acc,
          ...JSON.parse(edge.node.internal.content),
        };
      }, {}),
    [allRelatedDocsField]
  );

  const onSelect = (value, link) => {
    goToPlatform(relatedRoutes, value, location, link);
  };

  if (!selectedPlatform) return null;
  return (
    <SideNavDropdown
      title='Platform'
      onSelect={onSelect}
      selected={selectedPlatform?.title}
      options={copy.documentation.dropdown}
    />
  );
};

interface SideNavDropdownProps {
  title: string;
  onSelect: (value: string, link: string) => void;
  selected: string | Platforms;
  options: any[];
}

const SideNavDropdown = (sideNavDropdownProps: SideNavDropdownProps) => {
  const { onSelect, selected, options } = sideNavDropdownProps;
  return (
    <Flex
      minW={{ base: '0', sm: '224px', lg: '230px' }}
      w={{ base: '100%', sm: 'fit-content' }}
      justifyContent={'flex-start'}
      border='1px solid #CED4DA'
      borderRadius='4px'
      p='4px 10px'
    >
      <Dropdown
        onSelect={onSelect}
        selected={selected}
        options={options}
        cursor={'pointer'}
        zIndex={101}
      />
    </Flex>
  );
};

interface SideNavHeaderProps {
  pathname: string;
}

const DocumentationHeader = (props: SideNavHeaderProps) => {
  const pathInfo = getPathInfo(props.pathname);
  const headerState = useContext(HeaderState);
  const copy = useCopy(_copy);
  const buttons = copy.documentation.buttons?.[pathInfo.platform]?.buttons;
  if (!buttons) return null;

  return (
    <Flex
      width='100%'
      justify='center'
      alignItems='center'
      borderBottom='1px solid #CED4DA'
      py='12px'
      position='fixed'
      top='64px'
      bg='white'
      zIndex='100'
      transform={{
        base:
          headerState === 'hidden'
            ? `translate(0px, -64px)`
            : 'translate(0px, 0px)',
        lg: 'translate(0px, 0px)',
      }}
      transition={'transform 0.2s'}
      id='documentation-header'
    >
      <Flex
        width='100%'
        maxW='1200px'
        alignItems='center'
        mx={{
          base: '16px',
          md: '20px',
        }}
      >
        <PlatformDropdown pathname={props.pathname} pathInfo={pathInfo} />
        <Box bg='#CED4DA' width='1px' height='40px' mx='16px' />
        <DocumentationButtonGroup pathInfo={pathInfo} />
      </Flex>
    </Flex>
  );
};

export default DocumentationHeader;

export const allRelatedDocsFieldQuery = graphql`
  query AllRelatedDocsFieldQuery {
    allRelatedDocsField {
      edges {
        node {
          internal {
            content
          }
        }
      }
    }
  }
`;

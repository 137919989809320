/* eslint-disable no-var */ // Disablding because scripts need it
import React, {
  Ref,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Header, { RefHandler } from "../components/Header";
import DynamicScript from "../components/DynamicScript";
import Footer from "../components/Footer/Footer";
import { globalHistory } from "@reach/router";
import { isProd } from "../services/Analytics";

import {
  isDocPage,
  getPathInfo,
  setLastPage,
  makeConsistentURL,
  ensureTrailingSlash,
  isCoreGetStartedPage,
} from "../services/URL";
import {
  setLongLivedTrackingCookie,
  setSessionTrackingCookie,
  setPreferredPlatform,
  setMixpanelFromCookie,
} from "../services/TrackingService";
import { initCache, setPreferred } from "../services/DocumentationService";

import { ABProvider } from "@pdftron/react-ab";
import { Backends } from "../services/ABService";

import applyPolyfills from "./polyfill";

// ABService.registerExperiments({
//   // headerButtonTest: {
//   //   id: 'DQ3eCglbQSuoxGJ15zbvHw',
//   //   variantCount: 3,
//   //   testingId: 1
//   // }
// });

import "./MainLayout.scss";
import FooterContext from "../context/FooterContext";
import HeaderContext from "../context/HeaderContent";
import PlatformContext from "../context/PlatformContext";
import HeaderStateContext, { HeaderState } from "../context/HeaderState";
import { SearchPopupProvider } from "../components/SearchPopup/SearchPopupContext";
import { SearchPopupModal } from "../components/SearchPopup/SearchPopup";
import SEO from "../components/SEO";
import DocumentationHeader from "../components/DocumentationHeader/DocumentationHeader";
import { useSession } from "../hooks/useAuth";
import { Mixpanel } from "../util/mixpanel/mixpanel.client";
import { useDisclosure } from "@chakra-ui/react";
import { SignInModalContext } from "../context/SignInModalContext";
import SignInModal from "../components/SignInModal";
import { PROD_FIRST_CORE_PLATFORMS } from "../../config/platforms";

applyPolyfills();

export default function MainLayout({ location, children }) {
  const footerRef = useRef<HTMLDivElement>();
  const headerRef = useRef<HTMLDivElement>();
  const [headerState, setHeaderState] = useState<HeaderState>("visible");
  const [preferredPlatform, setPreferredPlatform] = useState<string | null>(
    null
  );
  const [lastClickedTab, setLastClickedTab] = useState<string | null>(null);
  const { isSignedIn, isLoading: isAuthStateLoading, user } = useSession();
  const {
    isOpen: isSignInModalOpen,
    onOpen: onSignInModalOpen,
    onClose: onSignInModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (isAuthStateLoading) return;
    if (isSignedIn && user?.id) {
      Mixpanel.identity(user.id);
      Mixpanel.people.set({
        $email: user.email,
        $name: user.name,
        country: user.country,
      });
    }
  }, [isSignedIn, isAuthStateLoading]);

  const getTitle = useCallback(() => {
    const path: string[] = location.pathname
      .split("/")
      .filter((p: string) => p);
    let title = "";
    // Use the last part of the path as the title
    if (path.length > 0) {
      title = path.slice(-1)[0] + " | ";
    }
    title = title.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
    return title;
  }, [location.pathname]);

  // Component did mount replacement
  useEffect(() => {
    initCache();

    setLongLivedTrackingCookie(location);
    setSessionTrackingCookie(location);
    setMixpanelFromCookie();
    const guideInfo = getPathInfo(location.pathname);
    const { platform } = guideInfo;

    try {
      window.dataLayer.push({ event: "optimize.activate" });
    } catch (e) {
      // nothing
    }
    if (location && location.pathname) {
      if (location.pathname.includes("/core/")) {
        if (location && location.search) {
          const searchParams = new URLSearchParams(location.search);
          const searchPlatform = searchParams.get("platform");
          if (searchPlatform) {
            // @ts-ignore
            setPreferred({ platform: searchPlatform });
            setPreferredPlatform(searchPlatform);
          }
        }
        if (isCoreGetStartedPage(location.pathname)) {
          const urlParts = ensureTrailingSlash(location.pathname).split(
            "/core/guides/get-started/"
          );
          let getStartedGuide = urlParts[1];
          //remove trailing slash
          getStartedGuide = getStartedGuide.replace(/\/$/, "");
          if (PROD_FIRST_CORE_PLATFORMS.includes(getStartedGuide)) {
            setPreferredPlatform(getStartedGuide);
            // @ts-ignore
            setPreferred({ platform: getStartedGuide });
          }
        }
      } else {
        // @ts-ignore
        setPreferred({ platform });
        setPreferredPlatform(platform);
      }
    }
  }, []);



  useEffect(() => {
    if (isProd()) {
      return globalHistory.listen(({ action }) => {
        if (action === "PUSH") {
          gtag("event", "page_view", {
            page_title: document.title,
            page_path: document.location.pathname,
            page_location: document.location.href,
            send_to: "UA-253343573-1",
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    try {
      // @ts-ignore
      window.Munchkin.munchkinFunction("visitWebPage", {
        url: this.props.location.pathname,
        params: document.location.search.substring(1),
      });
    } catch (e) {
      // nothing
    }

    if (location && location.pathname) {
      if (!location.pathname.includes("/core/")) {
        setPreferredPlatform(location.pathname);
      }
    }
    return () => setLastPage(location.pathname);
  }, [location.pathname]);

  const { pathname } = location;
  const docPage = useMemo(
    () => isDocPage(location.pathname),
    [location.pathname]
  );

  const onHeaderStateChanged = (newHeaderState: HeaderState) => {
    setHeaderState(newHeaderState);
  };
  return (
    <SearchPopupProvider>
      <HeaderContext.Provider value={() => headerRef.current}>
        <FooterContext.Provider value={() => footerRef.current}>
          <PlatformContext.Provider
            value={{
              platform: preferredPlatform,
              setPlatform: setPreferredPlatform,
              lastClickedTab,
              setLastClickedTab,
            }}
          >
            <ABProvider backend={Backends.GoogleOptimizeBackend}>
              <HeaderStateContext.Provider value={headerState}>
                <SignInModalContext.Provider
                  value={{
                    isSignInModalOpen,
                    onSignInModalClose,
                    onSignInModalOpen,
                  }}
                >
                  <div className="MainLayout" data-hj-ignore-attributes>
                    <SEO
                      title={`${getTitle()}Apryse Documentation`}
                      description={
                        "World's #1 PDF SDK Library for Web, Mobile, Server, Desktop"
                      }
                      image={"https://apryse.com/favicon.svg"}
                      canonical={makeConsistentURL(
                        `https://docs.apryse.com${pathname}`
                      )}
                      url={`https://docs.apryse.com${pathname}`}
                      siteName="Apryse Documentation"
                      type="website"
                      twitterCard="summary"
                      twitterSite="@apryse"
                    />
                    <SearchPopupModal />
                    <SignInModal />
                    <DynamicScript
                      name="AnalyticsJS"
                      run={() => {
                        const script = document.createElement("script");
                        script.type = "text/javascript";
                        script.charset = "UTF-8";
                        script.innerHTML = `
                        !(function() { var analytics = (window.analytics = window.analytics || []); if (!analytics.initialize) { if (analytics.invoked) { window.console && console.error && console.error('MetaRouter snippet included twice.') } else { analytics.invoked = !0; analytics.methods = [ 'trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on', 'addSourceMiddleware', 'addIntegrationMiddleware', 'setAnonymousId', 'addDestinationMiddleware', ]; analytics.factory = function (e) { return function () { var t = Array.prototype.slice.call(arguments); t.unshift(e); analytics.push(t); return analytics } }; for (var e = 0; e < analytics.methods.length; e++) { var key = analytics.methods[e]; analytics[key] = analytics.factory(key) } analytics.load = function (src, o) { analytics._loadOptions = o; var t = document.createElement('script'); t.type = 'text/javascript'; t.async = !0; t.src = ("https:" === document.location.protocol ? "https://" : "http://") + "cdn.calibermind.com/"+ src + ".js"; var n = document.getElementsByTagName('script')[0]; n.parentNode.insertBefore(t, n); }; analytics.SNIPPET_VERSION = '4.13.1'; analytics.load('a', { writeKey: 'tx8naeC5nMvkAByz3Xsd8'}); analytics.page(); }}})();`;
                        document.head.appendChild(script);
                      }}
                    />
                    <DynamicScript
                      name="Cookie Consent"
                      run={() => {
                        if (isProd()) {
                          const script = document.createElement("script");
                          script.src =
                            "https://cmp.osano.com/AzqNOUSrVpsgD1YIi/83b56f08-890a-4999-9914-6b5fe7cd40d7/osano.js";
                          document.body.appendChild(script);
                        }
                      }}
                      loadOnInteraction
                    />
                    <DynamicScript
                      name="Clearbit"
                      run={() => {
                        const script = document.createElement("script");
                        script.setAttribute("type", "text/javascript");
                        script.innerHTML = `
                        (function (d, u, h, s) {
                          h = d.getElementsByTagName('head')[0];
                          s = d.createElement('script');
                          s.async = 1;
                          s.src = u + new Date().getTime();
                          h.appendChild(s);
                        })(document, 'https://grow.clearbitjs.com/api/pixel.js?v=');`;
                        document.head.appendChild(script);
                      }}
                    />

                    <DynamicScript
                      name="HotJar"
                      loadOnInteraction
                      blackList={[/\/blog\//g]}
                      run={() => {
                        (function (h, o, t, j, a, r) {
                          h.hj =
                            h.hj ||
                            function () {
                              // @ts-ignore
                              // eslint-disable-next-line prefer-rest-params
                              (h.hj.q = h.hj.q || []).push(arguments);
                            };
                          h._hjSettings = { hjid: 3396065, hjsv: 6 };
                          a = o.getElementsByTagName("head")[0];
                          r = o.createElement("script");
                          r.async = 1;
                          r.src =
                            t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                          a.appendChild(r);
                        })(
                          window,
                          document,
                          "https://static.hotjar.com/c/hotjar-",
                          ".js?sv="
                        );
                      }}
                    />

                    <DynamicScript
                      name="LinkedIn tracking"
                      blackList={[/\/blog\//g]}
                      run={() => {
                        var _linkedin_partner_id = "2720420";
                        window._linkedin_data_partner_ids =
                          window._linkedin_data_partner_ids || [];
                        window._linkedin_data_partner_ids.push(
                          _linkedin_partner_id
                        );
                        (function (l) {
                          if (!l) {
                            // @ts-ignore
                            window.lintrk = function (a, b) {
                              // @ts-ignore
                              window.lintrk.q.push([a, b]);
                            };
                            // @ts-ignore
                            window.lintrk.q = [];
                          }
                          var s = document.getElementsByTagName("script")[0];
                          var b = document.createElement("script");
                          b.type = "text/javascript";
                          b.async = true;
                          b.src =
                            "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                          s.parentNode.insertBefore(b, s);
                        })(window.lintrk);
                      }}
                    />

                    <DynamicScript
                      name="Munchkin"
                      loadOnInteraction={true}
                      run={() => {
                        (function () {
                          var didInit = false;
                          function initMunchkin() {
                            if (didInit === false) {
                              didInit = true;
                              window.Munchkin.init("243-NCO-372", {
                                anonymizeIP: true,
                              });
                            }
                          }
                          var s = document.createElement("script");
                          s.type = "text/javascript";
                          s.async = true;
                          s.src = "//munchkin.marketo.net/munchkin.js";
                          // @ts-ignore
                          s.onreadystatechange = function () {
                            if (
                              this.readyState == "complete" ||
                              this.readyState == "loaded"
                            ) {
                              initMunchkin();
                            }
                          };
                          s.onload = initMunchkin;
                          document
                            .getElementsByTagName("head")[0]
                            .appendChild(s);
                        })();
                      }}
                    />

                    <DynamicScript
                      name="G2"
                      run={() => {
                        if (isProd()) {
                          const script = document.createElement("script");
                          script.innerHTML = `
                          (function (c, p, d, u, id, i) {
                          u = 'https://tracking.g2crowd.com/attribution_tracking/conversions/' + c + '.js?p=' + encodeURI(p);
                          i = document.createElement('script');
                          i.type = 'application/javascript';
                          i.async = true;
                          i.src = u;
                          d.getElementsByTagName('head')[0].appendChild(i);
                          }("1007198", document.location.href, document));`;
                          document.body.appendChild(script);
                        }
                      }}
                      loadOnInteraction
                    />

                    <div id="search" />

                    <div id="alert">
                      <p>Some test text!</p>
                    </div>

                    <Header
                      location={location}
                      pathname={location.pathname}
                      ref={headerRef as unknown as Ref<RefHandler>}
                      onStateChange={onHeaderStateChanged}
                    />

                    <div
                      className={`scrollable-body`}
                      // eslint-disable-next-line
                      tabIndex={1}
                    >
                      {docPage && (
                        <DocumentationHeader pathname={location.pathname} />
                      )}

                      <div className="page">{children}</div>
                      <Footer ref={footerRef} />
                    </div>
                  </div>
                </SignInModalContext.Provider>
              </HeaderStateContext.Provider>
            </ABProvider>
          </PlatformContext.Provider>
        </FooterContext.Provider>
      </HeaderContext.Provider>
    </SearchPopupProvider>
  );
}

import React, { useEffect, RefObject, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  HStack,
  useDisclosure,
  useMediaQuery,
  Spacer,
  Portal,
  MenuItem,
  Button
} from '@chakra-ui/react';
import Logo from '../../assets/inline/logo.svg';
import LogoMobile from '../../assets/inline/logo-mobile.svg';
import ChevronIcon from '../../icons/ChevronIcon';
import DiscordIcon from '../../icons/DiscordIcon';
import Link from '../Link';
import { Platforms } from '../../icons/PlatformIcon';

import { HandleNavClickProvider, HeaderContext } from './HeaderContext';
import SearchPopup from '../SearchPopup';
import { HeaderState } from '../../context/HeaderState';
import { mergeRefs } from '../../services/RefService';
import DownloadIcon from '../../icons/DownloadIcon';
import SideDrawer from '../SideDrawer/SideDrawer';
import Tooltip from '../Tooltip/Tooltip';
import SignInButton from '../SignInButton';

export const navCopy = [
  {
    title: 'Overview',
    to: '/',
    ariaLabel: 'Apryse documentation page',
  },
  {
    title: 'Documentation',
    to: '/guides/get-started/',
    ariaLabel: 'Apryse get started page',
  },
  {
    title: 'All Products',
    to: '/all-products/',
    ariaLabel: 'Apryse all products page',
  },
];

export enum Pages {
  OVERVIEW = 'Overview',
  DOCUMENTATION = 'Documentation',
  ALL_PRODUCTS = 'All Products',
}

export enum PageUrls {
  OVERVIEW = '/',
  DOCUMENTATION = '/guides/get-started/',
  ALL_PRODUCTS = '/all-products/',
}

export const getCurrentPage = (headerProps: HeaderProps) => {
  let currentPage = Pages.OVERVIEW
  if (headerProps.pathname === PageUrls.DOCUMENTATION) {
    currentPage = Pages.DOCUMENTATION
  } else if (headerProps.pathname === PageUrls.ALL_PRODUCTS) {
    currentPage = Pages.ALL_PRODUCTS
  }
  return currentPage
}

// Components
const DesktopNav = (navProps: NavProps) => {
  const { headerProps } = navProps;
  return (
    <HStack display={{ base: 'none', smd: 'flex' }} ml="10px" spacing="24px">
      {navCopy.map((navItem) => (
        <Link key={navItem.title} to={navItem.to} h="100%" ariaLabel={navItem.ariaLabel}>
          {headerProps.pathname === navItem.to ? (
            <Flex
              color="apryseLightBlue"
              fontWeight="500"
              fontSize="16px"
              lineHeight="24px"
              borderBottom="2px solid #00E2EA"
              pt="20px"
              pb="18px"
            >
              {navItem.title}
            </Flex>
          ) : (
            <Flex py="28px" color="white" fontWeight="500" fontSize="16px" lineHeight="24px">
              {navItem.title}
            </Flex>
          )}
        </Link>
      ))}
    </HStack>
  );
};

const MobileNav = (navProps: NavProps) => {
  const { headerProps } = navProps;
  const buttonText = getCurrentPage(headerProps);
  return (
    <Box display={{ base: 'flex', smd: 'none' }} ml="10px" h="100%">
      <Menu>
        <MenuButton color="white" fontWeight="500" fontSize="16px" lineHeight="24px">
          {buttonText}
          <ChevronIcon ml="8px" fill="white" />
        </MenuButton>
        <Portal>
          <MenuList padding="4px 0px" zIndex={1000} display={{ base: 'block', smd: 'none' }}>
            <Flex direction="column">
              {navCopy.map((navItem) => (
                <MenuItem key={navItem.title}>
                  <Link to={navItem.to} style={{width: '100%'}}>
                    <Flex
                      width="full"
                      minH="40px"
                      padding="12px 8px"
                      color="apryseIndigo"
                      fontWeight="700"
                      fontSize="14px"
                      lineHeight="16px"
                      _hover={{ bg: 'gray.400' }}
                    >
                      {navItem.title}
                    </Flex>
                  </Link>
                </MenuItem>
              ))}
            </Flex>
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  );
};

const HeaderLogo = () => (
  <Flex
    py={{ base: '12px', sm: '16px', lg: '18px' }}
    pl={{ base: '16px', sm: '20px', lg: '0px' }}
    pr={{ base: '16px', sm: '20px', lg: '20px' }}
  >
    <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
      <Link to="/" h="100%" ariaLabel="Apryse documentation page">
        <Box display={{ base: 'none', lg: 'block' }}>
          <Logo width="174px" height="28px" />
        </Box>
        <Box display={{ base: 'block', lg: 'none' }}>
          <LogoMobile width="40px" height="100%" />
        </Box>
      </Link>
    </Flex>
  </Flex>
);

export type RefHandler = {
  documentationHeaderRef: RefObject<HTMLDivElement>;
  headerRef: RefObject<HTMLDivElement>;
};

const Header = React.forwardRef<RefHandler, HeaderProps>(function HeaderWrapper(headerProps, ref) {
  const [hideHeader, setHideHeader] = useState(false);
  const [isLargerThan900] = useMediaQuery('(min-width: 900px)');
  const headerRef = useRef(null);
  const scrollTopRef = useRef(0);
  const { isOpen, onToggle } = useDisclosure();

  const onScroll = () => {
    const currScrollTop = getScrollTop();
    const direction = scrollTopRef.current - currScrollTop < 0 ? 'down' : 'up';

    if (direction === 'down' && !isOpen && currScrollTop >= 60) {
      setHideHeader(true);
      headerProps?.onStateChange('hidden');
    } else {
      setHideHeader(false);
      headerProps?.onStateChange('visible');
    }

    scrollTopRef.current = currScrollTop;
  };

  useEffect(() => {
    if (typeof window !== `undefined`) {
      scrollTopRef.current = getScrollTop();
    }

    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);



  return (
    <HeaderContext.Provider
      value={{
        onToggle
      }}
    >
      <HandleNavClickProvider>
        <Box
          id="header-navigation"
          position={'fixed'}
          top={'0px'}
          zIndex={101}
          width={'100%'}
          height="64px"
          transform={
            hideHeader && !isOpen && !isLargerThan900
              ? `translate(0px, -${isLargerThan900 ? 70 : 60}px)`
              : 'translate(0px, 0px)'
          }
          transition={'transform 0.2s'}
          alignItems={{ base: 'flex-start' }}
          display={'flex'}
          flexDirection={'column'}
          ref={mergeRefs([ref, headerRef])}
        >
          <Flex bgColor="apryseNavy" height="100%" width="100%" alignItems="center" justifyContent="center">
            <Flex width="100%" maxW="1200px" height="100%" alignItems="center">
              <Flex alignItems="center">
                <HeaderLogo />
                <DesktopNav headerProps={headerProps} />
                <MobileNav headerProps={headerProps} />
              </Flex>
              <Spacer />
              <Flex mr={{ base: '8px', sm: '20px' }} alignItems="center">
                <Box width='40px' height='40px' display='flex' alignItems='center' justifyContent='center'>
                  <Tooltip title='Search' height='24px'>
                    <SearchPopup nav pathname={headerProps.pathname} />
                  </Tooltip>
                </Box>
                <Box display={{ base: 'none', md: 'flex' }} width='40px' height='40px' alignItems='center' justifyContent='center' ml={2}>
                  <Tooltip title='Downloads' height='24px'>
                    <Link to='https://dev.apryse.com'>
                      <DownloadIcon w='24px' h='24px' color='white' />
                    </Link>
                  </Tooltip>
                </Box>
                <Box display={{ base: 'none', md: 'flex' }} width='40px' height='40px' alignItems='center' justifyContent='center' ml={2}>
                  <Tooltip title='Chat with us' height='24px'>
                    <Link to='https://apryse.com/discord?docLink=hdrDis'>
                      <DiscordIcon w='24px' h='24px' />
                    </Link>
                  </Tooltip>
                </Box>
                <Link to='https://apryse.com/form/contact-sales?docLink=hdrSal'>
                  <Button
                    bgColor='transparent'
                    border='2px solid white'
                    borderRadius='6px'
                    display={{ base: 'none', md: 'flex' }}
                    ml='8px'
                  >
                    Contact Sales
                  </Button>
                </Link>
                <SignInButton display={{ base: 'none', md: 'flex' }} />
                <SideDrawer headerProps={headerProps} />
              </Flex>
            </Flex>
          </Flex>
        </Box>

      </HandleNavClickProvider>
    </HeaderContext.Provider>
  );
});

// Types
export type HeaderProps = {
  location: string;
  pathname: string;
  onStateChange?: (headerState: HeaderState) => void;
};

export type NavProps = {
  headerProps: HeaderProps;
};

export interface DocumentHeaderProps extends HeaderProps {
  pathInfo?: {
    rootPath?: string;
    platform?: Platforms;
    section?: string;
    guidePath?: string;
  };
}

interface InfoSection {
  title: string;
  text: string;
  icon?: (props: any) => JSX.Element;
  to?: string;
  onClick?: () => void;
  items: {
    title: string;
    text: string;
    icon?: (props: any) => JSX.Element;
    to: string;
    onClick?: () => void;
  }[];
}

export type NavItemProps = {
  href?: string;
  title?: string;
  viewAllButtontitle?: string;
  children?: NavItemChild[];
  footer?: NavItemFooter;
  tabbed?: boolean;
  infoSection?: InfoSection;
  tabs?: string[];
  tabContent?: NavItemTabContent;
  tab?: string;
  column?: any;
  index?: number;
  onClose?(): void;
  viewAllButton?: NavButton;
  center?: boolean;
  footerButtonList?: {
    to: string;
    label: string;
  }[];
};

export type NavItemFooter = {
  actionButtons?: ActionButton[];
  linkButtons?: LinkButton[];
};

export type ActionButton = {
  content?: string;
  buttonContent?: string;
  to?: string;
  onClick?: () => void;
};

export type LinkButton = ActionButton & {
  // empty for now because at the moment, its same type as Action Button
};

export type NavItemChild = {
  title?: string;
  icon?: string;
  items?: NavItemChildItem[];
};

export type NavItemTabContent = {
  Web: NavItemTabContentColumn[];
  Mobile: NavItemTabContentColumn[];
  Desktop: NavItemTabContentColumn[];
  Server: NavItemTabContentColumn[];
};

export type NavItemTabContentColumn = {
  title: string;
  titleIcon?: string;
  items: NavButton[];
  button?: NavButton;
};

export type NavButton = {
  label: string;
  to: string;
};

export type NavItemChildItem = {
  title?: string;
  to?: string;
  icon?: (props: any) => JSX.Element;
  hasTitle?: boolean;
  onClose?(): void;
  navItemProps?: NavItemProps;
};

// Utilities
const getScrollTop = () => {
  return window?.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
};

export const clickTrack = (trail: string[]) => {
  const string = trail.reduce((acc, item) => {
    if (item) {
      acc += `${acc === '' ? '' : ' > '}${item}`;
    }
    return acc;
  }, '');
};

export const handleNavItemLinkClicked = (navItemProps: NavItemProps) => {
  if (navItemProps.href) {
    clickTrack([navItemProps.title]);
  }
};

export const handleNavItemChildLinkClicked = (navItemChildItem: NavItemChildItem) => {
  if (navItemChildItem.to) {
    clickTrack([navItemChildItem.navItemProps.title, navItemChildItem.title]);
  }
};

export default Header;
